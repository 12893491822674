import React from "react"
import { makeStyles } from "@material-ui/core/styles"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import Paper from "@material-ui/core/Paper"

const ProfileImage = () => {
  const data = useStaticQuery(graphql`
    query {
      profileImage: file(relativePath: { eq: "profile-img.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 600) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  const useStyles = makeStyles(theme => ({
    profileImgPaper: {
      marginLeft: "auto",
      marginRight: "auto",
      maxWidth: 300,
      borderRadius: 165,
      padding: 5,
    },
    profileImg: {
      borderRadius: 165,
    },
  }))

  const classes = useStyles()

  return (
    <Paper square elevation={3} className={classes.profileImgPaper}>
      <Img
        fluid={data.profileImage.childImageSharp.fluid}
        className={classes.profileImg}
      />
    </Paper>
  )
}

export default ProfileImage
